import type {
    ChangePasswordCreatePayload,
    DocumentId,
    ForgotPasswordCreatePayload,
    GetUserVerificationsParams,
    KycWellKnownCountries,
    LocalCreatePayload,
    LocalRegisterCreatePayload,
    ResetPasswordCreatePayload,
    SumSubTokenResponse,
    User,
    UserProfile,
    UsersPermissionsUserRegistration,
    UserVerificationListResponse,
    UserVerificationTypeEnum,
} from "src/types";
import { HttpMethod } from "../constants";
import { rootApi } from "../rootApi";
import { ENTITY_WITH_TRANSLATIONS_TAG, LOGGED_USER_TAG, WALLET_TAG } from "../tags";
import { getCmsUrl, getCoreUrl } from "../utils";

export const authApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<UsersPermissionsUserRegistration, LocalCreatePayload>({
            query: (loginData) => ({
                url: getCmsUrl("/api/auth/local"),
                method: HttpMethod.POST,
                body: loginData,
            }),
            invalidatesTags: [LOGGED_USER_TAG],
        }),
        registration: build.mutation<UsersPermissionsUserRegistration, LocalRegisterCreatePayload & { referralCode?: string }>({
            query: (registrationData) => ({
                url: getCmsUrl("/api/auth/local/register"),
                method: HttpMethod.POST,
                body: registrationData,
            }),
            invalidatesTags: [LOGGED_USER_TAG],
        }),
        forgotPassword: build.mutation<void, ForgotPasswordCreatePayload>({
            query: (forgotPasswordData) => ({
                url: getCmsUrl("/api/auth/forgot-password"),
                method: HttpMethod.POST,
                body: forgotPasswordData,
            }),
        }),
        changePassword: build.mutation<void, ChangePasswordCreatePayload>({
            query: (changePasswordData) => ({
                url: getCmsUrl("/api/auth/change-password"),
                method: HttpMethod.POST,
                body: changePasswordData,
            }),
        }),
        resetPassword: build.mutation<void, ResetPasswordCreatePayload>({
            query: (resetPasswordData) => ({
                url: getCmsUrl("/api/auth/reset-password"),
                method: HttpMethod.POST,
                body: resetPasswordData,
            }),
            invalidatesTags: [LOGGED_USER_TAG],
        }),
        user: build.query<User, void>({
            query: () => ({
                url: getCmsUrl("/api/users/me"),
                method: HttpMethod.GET,
                params: { populate: "*" },
            }),
            providesTags: [LOGGED_USER_TAG],
        }),
        kycToken: build.query<SumSubTokenResponse, void>({
            query: () => ({
                url: getCoreUrl("/user/kyc/sumsub/token"),
                method: HttpMethod.GET,
            }),
        }),
        verificationOptions: build.query<UserVerificationListResponse, GetUserVerificationsParams>({
            query: (params) => ({
                url: getCmsUrl("/api/user-verifications"),
                method: HttpMethod.GET,
                params,
            }),
        }),
        updateUserProfile: build.mutation<User, { profileDocumentId: DocumentId; data: UserProfile }>({
            query: ({ profileDocumentId, data }) => ({
                url: getCmsUrl(`/api/user-profiles/${profileDocumentId}`),
                method: HttpMethod.PUT,
                body: {
                    data,
                },
            }),
            invalidatesTags: [LOGGED_USER_TAG, ENTITY_WITH_TRANSLATIONS_TAG],
        }),
        addVerification: build.mutation<null, { userId: number; verificationType: UserVerificationTypeEnum }>({
            async queryFn({ userId, verificationType }, _queryApi, _extraOptions, fetchWithBaseQuery) {
                const { data: userVerifications } = (await fetchWithBaseQuery(getCmsUrl("/api/user-verifications"))) as {
                    data: UserVerificationListResponse;
                };
                if (!userVerifications?.data) {
                    throw new Error("No verification data found");
                }
                const verification = userVerifications?.data?.find((v) => v?.type === verificationType);
                if (!verification) {
                    throw new Error("Verification type not found");
                }
                await fetchWithBaseQuery({
                    url: getCmsUrl(`/api/users/${userId}`),
                    method: HttpMethod.PUT,
                    body: {
                        verification: {
                            connect: [
                                {
                                    id: verification.id,
                                },
                            ],
                        },
                    },
                });
                return {
                    data: null,
                };
            },
            invalidatesTags: [LOGGED_USER_TAG, ENTITY_WITH_TRANSLATIONS_TAG],
        }),
        publicGoogleJwt: build.query<{ jwt: string; user?: { email?: string } }, object>({
            query: (params) => ({
                url: getCmsUrl("/api/auth/google/callback"),
                method: HttpMethod.GET,
                params,
            }),
        }),
        countries: build.query<KycWellKnownCountries["countries"], void>({
            query: () => ({
                url: getCoreUrl("/user/kyc/countries"),
                method: HttpMethod.GET,
            }),
            transformResponse: (response: KycWellKnownCountries) => response.countries,
        }),
        validateReferralCode: build.query<{ valid: boolean }, { referralCode: string }>({
            query: (params) => ({
                url: getCmsUrl("/api/user-profiles/validate-referral-code"),
                method: HttpMethod.GET,
                params,
            }),
        }),
        deleteAccount: build.mutation<void, void>({
            query: () => ({
                url: getCmsUrl("/api/user-profiles/delete-account"),
                method: HttpMethod.DELETE,
            }),
            invalidatesTags: [LOGGED_USER_TAG],
        }),
        emptyOutWallet: build.mutation<void, void>({
            query: () => ({
                url: getCmsUrl("/api/user-profiles/empty-out-wallet"),
                method: HttpMethod.DELETE,
            }),
            invalidatesTags: [WALLET_TAG],
        }),
    }),
});

export const {
    useLoginMutation,
    useUserQuery,
    useLazyUserQuery,
    useRegistrationMutation,
    useResetPasswordMutation,
    useForgotPasswordMutation,
    useKycTokenQuery,
    useVerificationOptionsQuery,
    useAddVerificationMutation,
    useUpdateUserProfileMutation,
    useChangePasswordMutation,
    usePublicGoogleJwtQuery,
    useCountriesQuery,
    useLazyValidateReferralCodeQuery,
    useDeleteAccountMutation,
    useEmptyOutWalletMutation,
} = authApi;
