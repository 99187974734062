import { zodResolver } from "@hookform/resolvers/zod";
import type { FC } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button, CmsError, PopupWindow, SecretInput } from "src/components";
import { isCmsErrorResponseWithMessage, useChangePasswordMutation } from "src/serverApi";
import { useTranslation } from "src/translations";
import { passwordSchema } from "src/utils";
import { logger } from "../logger";

type FormFields = {
    currentPassword: string;
    password: string;
    passwordConfirmation: string;
};

export const ChangePasswordForm: FC = () => {
    const { t } = useTranslation();

    const [changePassword, { isLoading, isError, error }] = useChangePasswordMutation();

    const changePasswordForm = z
        .object({
            currentPassword: z.string().min(1, t("common.required.field")),
            passwordConfirmation: z.string().min(1, t("common.required.field")),
            password: passwordSchema(t),
        })
        .superRefine(({ passwordConfirmation, password }, ctx) => {
            if (passwordConfirmation !== password) {
                ctx.addIssue({
                    code: "custom",
                    message: t("settings.changePassword.noMatch"),
                    path: ["passwordConfirmation"],
                });
            }
        });

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isValid },
    } = useForm<FormFields>({
        mode: "onChange",
        resolver: zodResolver(changePasswordForm),
    });

    const onSubmit = async (data: FormFields) => {
        try {
            await changePassword(data).unwrap();
            await PopupWindow.fire({
                title: t("settings.changePassword.success"),
                icon: "success",
            });
            reset();
        } catch (err) {
            if (isCmsErrorResponseWithMessage(err)) {
                logger.error(err.data.error.message, err);
            } else {
                logger.error("Unknown error", err);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6">
            <SecretInput
                label={t("settings.changePassword.currentPassword")}
                {...register("currentPassword")}
                fullWidth
                error={errors.currentPassword?.message}
            />
            <SecretInput
                label={t("settings.changePassword.password")}
                {...register("password")}
                fullWidth
                error={errors.password?.message}
            />

            <SecretInput
                label={t("settings.changePassword.passwordConfirmation")}
                {...register("passwordConfirmation")}
                fullWidth
                error={errors.passwordConfirmation?.message}
            />

            {isError && <CmsError error={error} fallbackMessage={t("common.error")} />}

            <Button isLoading={isLoading} type="submit" fullWidth disabled={!isValid}>
                {t("settings.changePassword.submit")}
            </Button>
        </form>
    );
};
